import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { track } from "../../utils/Tracking";
import InfoButton from "../generic/InfoButton";
import classes from "./CTASection.module.css";

function CTASection() {
  const [ref, inView] = useInView();
  useEffect(() => {
    track("InView", { inView, section: "cta-section" });
  }, [inView]);

  function contactByEmail(e: React.MouseEvent, type: string) {
    e.preventDefault();

    let subject = "Maress general contact from summer campaign";
    if (type === "demo") {
      subject = "Maress demo booking from summer campaign";
      track("Button Click", { section: "cta-section", button: "request-demo" });
    } else {
      track("Button Click", { section: "cta-section", button: "request-help" });
    }

    window.location.href = `mailto:decarbonisation@v-p-s.com?subject=${subject}`;
  }
  return (
    <section className={classes.ctaSection} ref={ref}>
      <div className={classes.ctaSectionContent}>
        <div className={classes.ctaSectionPane}>
          <div className={classes.ctaSectionPaneContent}>
            <h3 className={classes.ctaTitle}>
              Curious about Maress and data-driven decarbonisation?
            </h3>
            <p className={classes.ctaText}>
              Book a demo to explore how we can support your company on your
              decarb journey!
            </p>
            <button
              aria-label="Contact us by email to request demo"
              className={classes.ctaButton}
              onClick={(e) => contactByEmail(e, "demo")}
            >
              <span>Book a demo</span>
            </button>
          </div>
        </div>
        <div className={classes.ctaSectionPane}>
          <div className={classes.ctaSectionPaneContent}>
            <h3 className={classes.ctaTitle}>
              Do you have any questions about the campaign?
            </h3>
            <p className={classes.ctaText}>
              Please contact us if you have any questions to decarb initiatives
              or the campaign in general.
            </p>

            <button
              aria-label="Contact us by email for campaign info"
              className={classes.ctaButton}
              onClick={(e) => contactByEmail(e, "contact")}
            >
              <span>Contact us</span>
            </button>
            <span className={classes.ctaInfoButtonWrapper}>
              <InfoButton section="cta-section" />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTASection;
