import clsx from "clsx";
import React, { useEffect } from "react";
import QlikEmbedModule from "../QlikEmbedModule";
import { Carousel } from "react-responsive-carousel";
import { useInView } from "react-intersection-observer";
import { track } from "../../utils/Tracking";

function MostEfficientByMode() {
  const [ref, inView] = useInView();
  useEffect(() => {
    track("most-efficient-mode", { inView });
  }, [inView]);

  const pieChartId = "YRYGkU";
  const charts = [
    { title: "PSV", id: "sGeTC" },
    { title: "AHTS", id: "ZJSHeS" },
    { title: "CSV", id: "dPknSjK" },
    { title: "Others", id: "tZptAge" },
  ];

  return (
    <div
      className={clsx(
        "section",
        "sectionExpandableTopList",
        "sectionExpandableTopListByMode"
      )}
      ref={ref}
    >
      <h4 className="sectionHeader">Most savings by mode</h4>
      <div className="sectionContent">
        <div className={clsx("modulePane", "modulePanePie")}>
          <div
            className={clsx(
              "qlik-module-item",
              "moduleItem",
              "moduleFuelSavedPieChartAbsolute"
            )}
          >
            <QlikEmbedModule
              id="fuel-saved-mode"
              // ui="classic/chart"
              ui="analytics/chart"
              appId="39dc7dd0-3d06-4bd7-9c9a-c058dc7343f9"
              objectId={pieChartId}
            />
            <div className="pieChartLabel">
              Distribution of C0<sub>2</sub> savings by mode
            </div>
          </div>
        </div>
        <div className={"modulePane modulePaneTopListAbsolute"}>
          <Carousel
            autoPlay
            infiniteLoop
            showArrows
            showStatus={false}
            swipeable
            width={
              typeof window && window.matchMedia("(max-width: 767px)").matches
                ? 310
                : 400
            }
            interval={8000}
            showThumbs={false}
          >
            {charts.map((chart) => (
              <div
                key={chart.id}
                className={clsx(
                  "qlik-module-item",
                  "moduleItem",
                  "itemTopList",
                  `moduleItem${chart.title}Absolute`
                )}
              >
                <QlikEmbedModule
                  id={`${chart.title.toLowerCase()}-absolute`}
                  ui="analytics/chart"
                  objectId={chart.id}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default MostEfficientByMode;
