import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect, useState } from "react";
import ImprovementByTypeAndMode from "./components/sections/ImprovementByTypeAndMode";
import ImprovementByType from "./components/sections/ImprovementsByType";
import MostEfficientByMode from "./components/sections/MostEfficientByMode";
import KPIs from "./components/sections/KPIs";
import CampaignBoard from "./components/sections/CampaignBoard";
import LogoSection from "./components/sections/LogoSection";
import LastYearSection from "./components/sections/LastYearSection";
import CTASection from "./components/sections/CTASection";
import * as amplitude from "@amplitude/analytics-browser";
import { track } from "./utils/Tracking";
import CampaignDetailsSection from "./components/sections/CampaignDetailsSection";
import InfoButton from "./components/generic/InfoButton";

function App() {
  const [showWholePageDescription, setShowWholePageDescription] =
    useState<boolean>(false);
  const [showTypeDetailsView, setShowTypeDetailsView] =
    useState<boolean>(false);

  // function resetAndReload() {
  //   window.sessionStorage.removeItem("qlik-user-id");
  //   document.location.reload();
  // }

  useEffect(() => {
    amplitude.init("38635c642f58f148c69df0faa8c8f700", {
      serverZone: "EU",
      defaultTracking: {
        attribution: true,
        pageViews: true,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
      },
    });

    if (window.matchMedia("(min-width: 768px)").matches) {
      setShowWholePageDescription(true);
    }

    track("pageView", {});
  }, []);

  return (
    <div className={"layout"}>
      <header className={"header"}>
        <div className={"logo"}>
          <a
            href="https://www.vpsveritas.com/maress-0"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/assets/images/maress-logo.svg" alt="Site logo" />
          </a>
        </div>
      </header>
      <div className={"main"}>
        <div className={"page"}>
          <header className={"pageHeader"}>
            <h1 className={"pageTitle"}>Summer decarb campaign 2024</h1>
            <div className="quickStats">
              <span>
                <b>300+</b> vessels
              </span>
              <span>&bull;</span>
              <span>
                <b>90</b> days
              </span>
              <span>&bull;</span>
              <span>
                World's leading <b>maritime emissions saving</b> campaign
              </span>
              <span>
                <InfoButton section="header-section" />
              </span>
            </div>
            <div
              className={`pageDescription ${
                showWholePageDescription ? "pageDescriptionShow" : ""
              }`}
            >
              <p>
                The Maress Summer Campaign exemplifies collaborative excellence.
                The forward-thinking companies participating this year have all
                agreed that when it comes to decarb, transparency is key.
                Learning from and motivating each other is crucial and we are
                excited to see what the overall CO<sub>2</sub> emissions
                reduction result will be this Summer. In this friendly
                competition every vessel is being measured against its own
                unique baseline, and the vessels that improve the most wins.
              </p>
              <p>
                Use this dashboard to keep track of the top performing offshore
                vessels per type as the campaign progresses. Although safety is
                always first, this Maress campaign puts true engagement on the
                agenda. Best of luck to all participants!
              </p>
              <p>
                Can we reach the goal of saving 15 000 mt C0<sub>2</sub>{" "}
                emissions?
              </p>
            </div>
            <div className="pageDescriptionReadMore">
              <button
                aria-label="View all intro text"
                onClick={() => {
                  track("Button Click", {
                    section: "page-header",
                    button: "show-whole-page-description",
                    show: !showWholePageDescription,
                  });
                  setShowWholePageDescription(!showWholePageDescription);
                }}
              >
                <span>{showWholePageDescription ? "Hide" : "Read more"}</span>
              </button>
            </div>
            <div className={"sdgs"}>
              <img
                src="/assets/images/sdg9.png"
                alt="Sustainability development goal 9"
              />
              <img
                src="/assets/images/sdg12.png"
                alt="Sustainability development goal 12"
              />
              <img
                src="/assets/images/sdg13.png"
                alt="Sustainability development goal 13"
              />
              <img
                src="/assets/images/sdg14.png"
                alt="Sustainability development goal 14"
              />
            </div>
          </header>
          <div className={"pageContent"}>
            <div className={"appsWrapper"}>
              <div className={"grid"}>
                <CampaignBoard />
                <ImprovementByType
                  setShowTypeDetailsView={() => {
                    track("Button Click", {
                      section: "improvement-by-type",
                      button: "show-detail-tables",
                      show: showTypeDetailsView,
                    });
                    setShowTypeDetailsView(!showTypeDetailsView);
                  }}
                  showTypeDetailsView={showTypeDetailsView}
                />
                <MostEfficientByMode />
                <ImprovementByTypeAndMode isVisible={showTypeDetailsView} />
                <KPIs />
              </div>
            </div>
            <LogoSection />
            <CTASection />
            <CampaignDetailsSection />
          </div>
          <LastYearSection />
          <footer className="pageFooter">
            <div className="pageFooterContent">
              <div className="allrights">© VPS Decarbonisation AS</div>
              <div className="logoWrapper">
                <a
                  href="https://www.vpsveritas.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>A campaign by </span>
                  <img src="/assets/images/vps-logo.svg" alt="VPS logo" />
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default App;
