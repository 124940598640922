import clsx from "clsx";
import React, { useEffect } from "react";
import QlikEmbedModule from "../QlikEmbedModule";
import { useInView } from "react-intersection-observer";
import { track } from "../../utils/Tracking";

function CampaignBoard() {
  const [ref, inView] = useInView();
  useEffect(() => {
    track("InView", { inView, section: "campaign-board" });
  }, [inView]);

  const co2savingsId = "EnVCpc";
  const co2savingsGoalId = "GcGfVT";
  const fuelSavingsId = "YtCaSxa";
  // const campaignProgressGraphId = "PcxTEw";
  // const campaignProgressGraphId = "HCgVfzf";
  const campaignProgressGraphId = "XbxfJY";

  return (
    <div className={clsx("section", "campaignBoardSection")} ref={ref}>
      <h4 className="sectionHeader">
        CO<sub>2</sub> savings
      </h4>
      <div className={"campaignBoardSectionContent"}>
        <div className={clsx("modulePane", "modulePaneCo2Kpi")}>
          <div
            className={clsx("qlik-module-item", "moduleItem", "moduleCO2Kpi")}
          >
            <QlikEmbedModule
              id="co2-savings"
              ui="analytics/chart"
              objectId={co2savingsId}
            />
          </div>
          <div
            className={clsx(
              "qlik-module-item",
              "moduleItem",
              "moduleCO2GoalKpi"
            )}
          >
            <div className="moduleItemContent">
              <div className="moduleItemTitle">Campaign goal</div>
              <QlikEmbedModule
                id="co2-savings-goal"
                ui="analytics/chart"
                objectId={co2savingsGoalId}
              />
              <div className="moduleItemUnit">mt</div>
            </div>
          </div>
        </div>
        <div className={clsx("modulePane", "modulePaneProgress")}>
          <div
            className={clsx(
              "qlik-module",
              "moduleItem",
              "module",
              "moduleKpi",
              "moduleFuelKpi"
            )}
          >
            <QlikEmbedModule
              id="fuel-savings"
              ui="analytics/chart"
              objectId={fuelSavingsId}
            />
          </div>
          <div
            className={clsx(
              "qlik-module-item",
              "moduleItem",
              "moduleProgressItem"
            )}
          >
            <QlikEmbedModule
              id="goal-progress"
              ui="analytics/chart"
              objectId={campaignProgressGraphId}
            />
          </div>
        </div>
        <div className="campaignSectionIcon">
          <img src="/assets/images/emission-icon.svg" alt="Emission symbol" />
        </div>
      </div>
    </div>
  );
}

export default CampaignBoard;
