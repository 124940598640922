type Props = {
  width?: number;
  height?: number;
};

function InfoIcon({ width, height }: Props) {
  const _width = width || 100;
  const _height = height || 100;
  return (
    <svg
      width={_width}
      height={_height}
      viewBox={`0 0 100 100`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 90C72.09 90 90 72.09 90 50C90 27.91 72.09 10 50 10C27.91 10 10 27.91 10 50C10 72.09 27.91 90 50 90ZM50 100C77.61 100 100 77.61 100 50C100 22.39 77.61 0 50 0C22.39 0 0 22.39 0 50C0 77.61 22.39 100 50 100Z"
        fill="currentColor"
      />
      <path
        d="M55.0298 31.2009C55.0598 28.4409 52.8498 26.1709 50.0898 26.1409C47.3298 26.1109 45.0598 28.3209 45.0298 31.0809L44.9998 33.9409C44.9698 36.7009 47.1798 38.9709 49.9398 39.0009C52.6998 39.0309 54.9698 36.8209 54.9998 34.0609L55.0298 31.2009Z"
        fill="currentColor"
      />
      <path
        d="M55 47.99C55 45.23 52.75 42.99 49.99 43C47.23 43.01 44.99 45.25 45 48.01L45.03 68.93C45.03 71.69 47.28 73.93 50.04 73.92C52.8 73.91 55.04 71.67 55.03 68.91L55 47.99Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default InfoIcon;
