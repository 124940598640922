import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { track } from "../../utils/Tracking";
import InfoIcon from "../generic/InfoIcon";

function CampaignDetailsSection() {
  const [ref, inView] = useInView();

  const [show] = useState<boolean>(false);

  useEffect(() => {
    track("InView", { inView, section: "campaign-details-section" });
  }, [inView]);

  function hideView(e: React.MouseEvent) {
    track("Button Click", {
      section: "campaign-details-section",
      button: "hide-campaign-details-section",
    });
    const wrapper = document.getElementById("campaign-details"); // I KNOW
    if (wrapper) wrapper.classList.remove("detailsSection--visible");
  }

  return (
    <div
      className={`detailsSection ${show ? "detailsSection--visible" : ""}`}
      ref={ref}
      id="campaign-details"
    >
      <div className="detailsSectionContent">
        <div>
          <InfoIcon width={50} height={50} />
        </div>
        <h3>Winners and Categories</h3>
        <p>
          The campaign leaderboard is categorized by vessel segment types. The
          vessel segments represented in the campaign are PSV, AHTS, CSV, and
          Other. Each vessel segment will have its own leaderboard for the
          following categories:
        </p>
        <ol>
          <li>Most Improved Vessel Overall - Top 5</li>
          <li>Most Efficient Vessel in Transit - Winner only</li>
          <li>Most Efficient Vessel in DP - Winner only</li>
          <li>Most Efficient Vessel in Standby - Winner only</li>
          <li>Most Efficient Vessel in Port - Winner only</li>
        </ol>

        <p>
          <b>Disclaimer:</b>
        </p>

        <p>
          Please note that external factors such as weather and operational
          requirements can affect the efficiency and improvement results for the
          campaign vessels. These factors are not accounted for by the campaign
          dashboard.
        </p>

        <p>
          Additionally, vessel size, dimensions, and specifications play a role
          in performance. While the dashboard categorizes vessels into segments,
          this segmentation is not detailed enough to capture these variations
          accurately.
        </p>

        <h3>Improvement and Efficiency Metrics</h3>
        <p>
          Improvement is measured by comparing fuel consumption during the
          campaign with historical baseline data. This comparison estimates what
          the vessel's consumption would have been in the campaign if it had
          maintained its historic baseline consumption for the same operational
          profile.
        </p>
        <p>
          Efficiency is determined by identifying which vessel uses the least
          amount of fuel per time in an operational mode. For the transit mode,
          efficiency is evaluated based on the number of kilograms of fuel
          consumed per nautical mile.
        </p>

        <h3>Filters and Data Quality</h3>
        <p>
          The campaign relies on the vessels' self-reported fuel consumption
          data. To ensure fairness and accuracy, several filters have been
          implemented to eliminate obvious data inaccuracies:
        </p>
        <ul>
          <li>
            Fuel consumption must fall within a specified minimum and maximum
            range, depending on the segment and mode.
          </li>
          <li>
            Vessels must have spent a minimum number of hours in an operating
            mode for the data to be included in the campaign.
          </li>
        </ul>
        <h3>Update frequency</h3>
        <p>Data is updated daily throughout the campaign period.</p>
        <div className="buttons" onClick={hideView}>
          <button
            className="closeDetailsButton"
            aria-label="Hide campaign details"
          >
            <span>Okay, got it</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CampaignDetailsSection;
