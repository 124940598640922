// export const qlikConfig = {
//   authType: "oauth2",
//   host: "https://nsinsights.eu.qlikcloud.com",
//   clientId: "9d9378cc2e63b002be21575470f64195", //process.env["clientId"],
//   clientSecret:
//     "99d113da6b0f7574286f49bbab4497136367032796fc76bdf03f349c29d04f98", //process.env["clientSecret"],
//   //   scope: "admin_classic user_default",
// } as any;

export const qlikConfig = {
  authType: "oauth2",
  host: "https://nsinsights.eu.qlikcloud.com",
  clientId: "98b392eb7d294002a0710ce34fe18722", //process.env["clientId"],
  clientSecret:
    "f52d050ba268500a72190abf1a00f5d7a1060d7831d2022c93a9be39abab2651", //process.env["clientSecret"],
  //   scope: "admin_classic user_default",
} as any;

export const APP_ID = "0077d83a-4d7f-4143-8b1d-d991e2b4092c";
