import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { track } from "../../utils/Tracking";
import classes from "./LastYearSection.module.css";
import clsx from "clsx";

function LastYearSection() {
  const [ref, inView] = useInView();
  useEffect(() => {
    track("InView", { inView, section: "last-year-section" });
  }, [inView]);

  return (
    <div className={classes.lastyear} ref={ref}>
      <div className={classes.lastyearContent}>
        <div className={classes.vessels}>
          <div className={clsx(classes.vesselContainer, classes.vesselPSV)}>
            <div
              className={clsx(classes.vesselImage, classes.vesselImageMokster)}
              style={{
                backgroundImage:
                  "url(/assets/images/mokster-stril-barents.jpg)",
              }}
            ></div>
            <div className={classes.vesselTypeLabel}>
              <span>Most improved PSV</span>
            </div>
            <div className={classes.vesselName}>
              <div className={classes.vesselNameLabel}>Stril Barents</div>
              <div>Simon Møkster shipping</div>
            </div>
          </div>
          <div className={clsx(classes.vesselContainer, classes.vesselAHTS)}>
            <div
              className={clsx(classes.vesselImage, classes.vesselImageDof)}
              style={{
                backgroundImage: "url(/assets/images/dof-skandi-atlantic.jpg)",
              }}
            ></div>
            <div className={classes.vesselTypeLabel}>
              <span>Most improved AHTS</span>
            </div>
            <div className={classes.vesselName}>
              <div className={classes.vesselNameLabel}>Skandi Atlantic</div>
              <div>DOF</div>
            </div>
          </div>
          <div className={clsx(classes.vesselContainer, classes.vesselCSV)}>
            <div
              className={clsx(classes.vesselImage, classes.vesselImageSolstad)}
              style={{
                backgroundImage:
                  "url(/assets/images/solstad-normand-frontier.jpg)",
              }}
            ></div>
            <div className={classes.vesselTypeLabel}>
              <span>Most improved CSV</span>
            </div>
            <div className={classes.vesselName}>
              <div className={classes.vesselNameLabel}>Normand Frontier</div>
              <div>Solstad</div>
            </div>
          </div>
          <div className={classes.competitionTitle}>
            <div className={classes.competitionTitleYear}>2023</div>
            <div>winners</div>
          </div>
        </div>
        <div className={classes.award}>
          <div className={classes.awardContent}>
            <a
              target="_blank"
              rel="nofollow noreferrer"
              href="https://www.v-p-s.com/knowledgecentre/news/vps-wins-osj-annual-environment-award-2024-maress-summer-campaign"
            >
              <div className={classes.awardImage}>
                <img
                  src="/assets/images/OSJ-award.png"
                  alt="OSJ Award 2023 - Environmental winner"
                />
              </div>
              <div className={classes.awardText}>
                VPS wins OSJ annual environmental award 2024 for Maress summer
                campaign 2023.
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LastYearSection;
