//@ts-nocheck
import { useEffect, useRef, useState } from "react";
import { APP_ID } from "../utils/Qlik";
import { SvgSpinners180RingWithBg } from "./generic/Preloader";

type Props = {
  id?: string;
  ui: string;
  appId?: string;
  objectId: string;
};

function countNodes(node) {
  var i = 0,
    c = node.childNodes.length,
    result = c;
  for (; i < c; i++) result += countNodes(node.childNodes[i]);
  return result;
}

export default function QlikEmbedModule(props: Props) {
  const [preparingQlikElement, setPreparingQlikElement] =
    useState<boolean>(false);
  const mounted = useRef<boolean>(false);
  const embedWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new MutationObserver(function (_, observer) {
      const nodes = countNodes(embedWrapperRef.current);
      if (nodes > 3) {
        observer.disconnect();
        setTimeout(() => {
          if (mounted.current) setPreparingQlikElement(true);
        }, 1000);
      }
    });
    if (embedWrapperRef.current) {
      observer.observe(embedWrapperRef.current, {
        subtree: true,
        childList: true,
      });
    }
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  const namespacedAttributes = {
    "context:json": "{interactions:{select: false, edit: false}}",
  };

  return (
    <div ref={embedWrapperRef} className="moduleItemWrapper">
      <qlik-embed
        {...props}
        app-id={APP_ID}
        object-id={props.objectId}
        {...namespacedAttributes}

        // context:json="{interactions:{select: false, edit: false}}"
      ></qlik-embed>

      {!preparingQlikElement && (
        <div className="preloader">
          <SvgSpinners180RingWithBg />
        </div>
      )}
    </div>
  );
}
