import clsx from "clsx";
import React, { useEffect } from "react";
import QlikEmbedModule from "../QlikEmbedModule";
import { useInView } from "react-intersection-observer";
import { track } from "../../utils/Tracking";

type Props = {
  setShowTypeDetailsView: () => void;
  showTypeDetailsView: boolean;
};

function ImprovementByType({
  showTypeDetailsView,
  setShowTypeDetailsView,
}: Props) {
  const [ref, inView] = useInView();
  useEffect(() => {
    track("InView", { inView, section: "improvement-overall" });
  }, [inView]);

  const pieChartId = "YGEbQM";
  const charts = [
    { title: "PSV", id: "kdsbYg" },
    { title: "AHTS", id: "jJQUNAR" },
    { title: "CSV", id: "hPYcca" },
    { title: "Others", id: "PwFwQ" },
  ];

  return (
    <div
      className={clsx(
        "section",
        "sectionExpandableTopList",
        "sectionExpandableTopListByType"
      )}
      ref={ref}
    >
      <h4 className="sectionHeader">Most improved, leaderboard</h4>
      <div className="sectionContent">
        <div
          className={clsx("qlik-module-item", "moduleItem", "moduleItemPie")}
        >
          <div className="moduleItemContent">
            <QlikEmbedModule
              id="fuel-saved-type"
              // ui="classic/chart"
              ui="analytics/chart"
              appId="39dc7dd0-3d06-4bd7-9c9a-c058dc7343f9"
              objectId={pieChartId}
            />
            <div className="pieChartLabel">
              Distribution of CO<sub>2</sub> savings by vessel type
            </div>
          </div>
        </div>
        {charts.map((chart) => (
          <div
            key={chart.id}
            className={clsx(
              "qlik-module-item",
              "moduleItem",
              "itemTopList",
              `moduleItem${chart.title}`
            )}
          >
            <QlikEmbedModule
              id={`${chart.title.toLowerCase()}-relative`}
              ui="analytics/chart"
              objectId={chart.id}
            />
          </div>
        ))}
      </div>
      <div className="buttons">
        <button
          aria-label="Show or hide efficiency detail tables"
          className="expandTypeTableButton"
          onClick={setShowTypeDetailsView}
        >
          <span>
            {showTypeDetailsView ? "Hide" : "Expand"} details of all modes
          </span>
        </button>
        {showTypeDetailsView && (
          <img
            className="arrow"
            src="/assets/images/arrow.png"
            alt="arrow"
            width={79}
          />
        )}
      </div>
    </div>
  );
}

export default ImprovementByType;
