import clsx from "clsx";
import React, { useEffect } from "react";
import QlikEmbedModule from "../QlikEmbedModule";
import { useInView } from "react-intersection-observer";
import { track } from "../../utils/Tracking";

function KPIs() {
  const [ref, inView] = useInView();
  useEffect(() => {
    track("InView", { inView, section: "simple-kpis-section" });
  }, [inView]);

  const charts = [
    { title: "Battery Utilization", slug: "battery-util", id: "jhdrC" },
    { title: "Average improvement", slug: "avg-improvement", id: "RxjdVjH" },
    { title: "Average consumption", slug: "avg-consumption", id: "FtxKpd" },
    { title: "Average saved CO2", slug: "avg-saved-co2", id: "jpmMVak" },
    {
      title: "Shorepower utilization",
      slug: "shorepower-utilization",
      id: "nTkKVZE",
    },
  ];

  return (
    <div className={clsx("section", "sectionKpis")} ref={ref}>
      <div className={clsx("sectionContent", "sectionContentKpis")}>
        {charts.map((chart) => (
          <div
            key={chart.id}
            className={clsx("qlik-module", "moduleItem", "module", "moduleKpi")}
          >
            <QlikEmbedModule
              id={chart.slug}
              ui="analytics/chart"
              objectId={chart.id}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default KPIs;
