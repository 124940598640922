import { track } from "../../utils/Tracking";
import InfoIcon from "./InfoIcon";

type Props = {
  section: string;
};

function InfoButton({ section }: Props) {
  function onClick(e: React.MouseEvent) {
    e.preventDefault();

    const detailsSection = document.getElementById("campaign-details");
    if (detailsSection) {
      track("Button Click", {
        section,
        button: "show-campaign-details-section",
      });
      detailsSection?.classList.add("detailsSection--visible");
      detailsSection.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <button
      onClick={onClick}
      className="infoButton"
      aria-label="Show campaign details"
    >
      <span className="infoButtonSpan">
        <InfoIcon width={20} height={20} />
      </span>
    </button>
  );
}

export default InfoButton;
