import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { track } from "../../utils/Tracking";
import classes from "./LogoSection.module.css";

function LogoSection() {
  const [ref, inView] = useInView();
  useEffect(() => {
    track("InView", { inView, section: "logo-section" });
  }, [inView]);

  const logos = [
    { title: "Simon Møkster Shipping", file: "mokster-logo" },
    { title: "Tidewater", file: "tidewater-logo" },
    { title: "Rem Offshore", file: "rem-logo" },
    { title: "Solstad", file: "solstad-logo" },
    { title: "Havila", file: "havila-logo" },
    { title: "Maersk", file: "maersk-logo" },
    { title: "Olympic", file: "olympic-logo" },
    { title: "Oceaneering", file: "oceaneering-logo" },
    { title: "Dof", file: "dof-logo" },
    { title: "Sea1", file: "sea1-logo" },
    { title: "Sanco Shipping", file: "sanco-logo" },
    { title: "Skansi Offshore", file: "skansi-logo" },
  ];

  return (
    <section className={classes.logoSection} ref={ref}>
      <div className={classes.logoSectionContent}>
        <h2 className={classes.sectionTitle}>Our participants</h2>
        <ul className={classes.logoSectionList}>
          {logos.map((c: any) => (
            <li className={classes.logoListItem} key={c.file}>
              <div className={classes.logoListItemContent}>
                <img
                  src={`/assets/images/logos/${c.file}.png`}
                  alt={`${c.title} logo`}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default LogoSection;
