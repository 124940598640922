import clsx from "clsx";
import React, { useEffect } from "react";
import QlikEmbedModule from "../QlikEmbedModule";
import { useInView } from "react-intersection-observer";
import { track } from "../../utils/Tracking";

type Props = {
  isVisible: boolean;
};

function ImprovementByTypeAndMode({ isVisible }: Props) {
  const [ref, inView] = useInView();
  useEffect(() => {
    track("InView", { inView, section: "improvement-detail-tables" });
  }, [inView]);

  const charts = [
    {
      title: "PSV",
      id: "psv",
      modes: [
        { title: "DP", id: "XqujqWK" },
        { title: "Standby", id: "dvYwLQj" },
        { title: "Transit", id: "eWqcv" },
        { title: "Port", id: "mPVrjP" },
      ],
    },
    {
      title: "AHTS",
      id: "ahts",
      modes: [
        { title: "DP", id: "CErayy" },
        { title: "Standby", id: "wNfpQp" },
        { title: "Transit", id: "KXVvvpB" },
        { title: "Port", id: "YdSSv" },
      ],
    },
    {
      title: "CSV",
      id: "csv",
      modes: [
        { title: "DP", id: "qVxfe" },
        { title: "Standby", id: "ZzYeT" },
        { title: "Transit", id: "yUySKg" },
        { title: "Port", id: "uPaRcpS" },
      ],
    },
    {
      title: "Others",
      id: "others",
      modes: [
        { title: "DP", id: "VAP" },
        { title: "Standby", id: "VjEAR" },
        { title: "Transit", id: "CrcqA" },
        { title: "Port", id: "BwRhJgh" },
      ],
    },
  ];

  return (
    <div
      className={clsx(
        "section",
        "sectionSpecificTopListByModeAndType",
        isVisible ? "visible" : ""
      )}
      ref={ref}
    >
      <h4 className="sectionHeader">
        Most improved vessels by mode, leaderboards
      </h4>
      <div className="sectionContent">
        {charts.map((chart) =>
          chart.modes.map((mode) => (
            <div
              key={mode.id}
              className={clsx(
                "qlik-module-item",
                "moduleItem",
                "itemModeSpecific",
                `moduleItem${chart.title}`,
                `moduleItem${mode.title}`,
                `moduleItem${chart.title}${mode.title}`
              )}
            >
              <QlikEmbedModule
                id={`${chart.title.toLowerCase()}-${mode.title.toLowerCase()}`}
                ui="analytics/chart"
                objectId={mode.id}
              />
            </div>
          ))
        )}
      </div>
      <div className="sectionFooterText">
        * The data is updated daily, and inaccuracies in table data may
        temporarily occur, but will not affect the outcome of the competition
      </div>
    </div>
  );
}

export default ImprovementByTypeAndMode;
